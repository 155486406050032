//时间选择快捷
export const dateShortcuts = [
    {
        text: "今日",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(
                new Date(new Date().toLocaleDateString()).getTime() +
                3600 * 1000 * 24 -
                1
            );
            start.setTime(
                new Date(new Date().toLocaleDateString()).getTime()
            );
            picker.$emit("pick", [start, end]);
        },
    },
    {
        text: "最近一周",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
        },
    },
    {
        text: "最近一个月",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
        },
    },
    {
        text: "最近三个月",
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
        },
    },
]
//当天选择时间戳
export const toDaytime = [
    new Date(new Date().toLocaleDateString()).getTime(),
    new Date(new Date().toLocaleDateString()).getTime() +
    3600 * 1000 * 24 -
    1,
]

