<template>
  <div class="notice">
    <notice-nav @change="handleDateChange"></notice-nav>
    <list :list="list" />
    <pagination
      :page="page"
      :pageSize="pageSize"
      :total="total"
      @sizeChange="handlePageSizeChange"
      @pageChange="handlePageChange"
    >
    </pagination>
  </div>
</template>
<script>
import NoticeNav from "./components/Nav";
import List from "./components/List";
export default {
  name: "Notice",
  components: { NoticeNav, List },
  data() {
    return {
      list: [],
      pageSize: 10,
      page: 1,
      total: 10,
    };
  },
  created() {
    this.getNoticeList();
  },
  activated() {
    this.getNoticeList();
  },
  methods: {
    //获取公告列表
    async getNoticeList() {
      const res = await this.$store.dispatch("getNoticeList", {
        page: this.page,
        pageSize: this.pageSize,
        startTime: this.startTime,
        endTime: this.endTime,
      });
      this.list = res.items;
      this.total = res.total;
    },
    handlePageSizeChange() {},
    handlePageChange() {},
    //时间筛选
    handleDateChange({ startTime, endTime }) {
      this.startTime = startTime;
      this.endTime = endTime;
      this.getNoticeList();
    },
  },
};
</script>
<style lang="less" scoped>
.notice {
  width: 100%;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 14px;
      line-height: 2em;
    }
  }
}
</style>