<template>
  <table-container :list="list">
    <el-table-column type="index" label="序号" width="50"> </el-table-column>
    <el-table-column prop="title" label="标题" align="left"> </el-table-column>
    <el-table-column
      prop="created_at"
      label="创建日期"
      width="200"
      align="center"
    >
    </el-table-column>
    <el-table-column label="操作" width="100" align="center">
      <template slot-scope="scope">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleCheck(scope.row)"
          >查看</el-button
        >
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    handleCheck(item) {
      this.$router.push({
        name: "NoticeDetail",
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>